import React from 'react';
import '../styles/Hero.css';

function Hero() {
  return (
    <div className="hero">
    </div>
  );
}

export default Hero;
