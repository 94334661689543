import React from 'react';
import Slider from 'react-slick';
import '../styles/Section4.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

function Section4() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    className: 'center',
  };

  return (
    <div className="section4">
      <Slider {...settings}>
        <div>
          <img src="/assets/meme1.png" alt="Image 1" className="carousel-image" />
        </div>
        <div>
          <img src="/assets/no.png" alt="Image 2" className="carousel-image" />
        </div>
        <div>
          <img src="/assets/maybe.png" alt="Image 3" className="carousel-image" />
        </div>
      </Slider>
    </div>
  );
}

export default Section4;
