import React, { useState } from 'react';
import '../styles/Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="/assets/logo.png" alt="Logo" />
      </div>
      <div className="navbar-hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`navbar-right ${isOpen ? 'open' : ''}`}>
        <div className="navbar-links">
          <a href="#home" onClick={toggleMenu}>Home</a>
          <a href="#tokenomics" onClick={toggleMenu}>Tokenomics</a>
          {/* <a href="#roadmap" onClick={toggleMenu}>Roadmap</a> */}
          <a href="#socials" onClick={toggleMenu}>Socials</a>
        </div>
        <div className="navbar-button">
          <a href="https://dexscreener.com/solana/8pRyw591xMWmfnSLgVQHeBM8g7a8FWj5NkJrQ8xve8dh" className="button chart" onClick={toggleMenu}>
            Chart
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
