import React from 'react';
import '../styles/Tokenomics.css';

function Tokenomics() {
  return (
    <section id="tokenomics" className="tokenomics">
      <h1>Tokenomics</h1>
      <h2>Total Supply: 1B<br />
      LP Burned: 100%<br />
      Tax: 0%<br />
      Drem: 100%</h2>
    </section>
  );
}

export default Tokenomics;
