import React from 'react';
import '../styles/Socials.css';

function Socials() {
  return (
    <section id="socials" className="socials">
      <h1>Socials</h1>
      <p>$DREM is a meme coin and is intended for entertainment purposes only. Participants acknowledge there is no reasonable belief for monetary gain</p>
      <div className="social-icons">
        <a href="https://t.me/Ihabdrem_SOL" className="social-icon">
          <img src="/assets/telegram.svg" alt="Telegram" />
        </a>
        <a href="https://x.com/IHabDrem_SOL" className="social-icon">
          <img src="/assets/twitter.svg" alt="Twitter" />
        </a>
      </div>
    </section>
  );
}

export default Socials;
