import React from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import Drem from './Drem';
import Tokenomics from './Tokenomics';
import Roadmap from './Roadmap';
import Socials from './Socials';
import Section4 from './Section4';
import '../styles/globals.css';

function Home() {
  return (
    <div id="home">
      <Navbar />
      <div className="main-content">
        <Hero />
        <Drem />
        <Tokenomics />
        <Section4 /> {/* Add the new Section4 component here */}
        <Socials />
      </div>
    </div>
  );
}

export default Home;
