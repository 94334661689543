import React from 'react';
import '../styles/Drem.css';

function Drem() {
  return (
    <section id="drem" className="drem">
      <h1>I HAB DREM</h1>
      <h2>THAT BLAH BLAH BLAH<br />
          BLAH BLAH BLAH BLAH<br />
          BLAH BLAH BLAH BLAH
      </h2>
      <h3>MARTUN</h3>
    </section>
  );
}

export default Drem;
